// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScroolToTop";
import LandingPage from "./pages/LandingPage/LandingPage";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/services_details" element={<Services_Details />} />
        <Route path="/project_detail" element={<Project_Detail />} />
        <Route path="/whatsapp" element={<WhatsAppRedirect />} /> */}
      </Routes>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import "./Footer.scss";
import footerLogo from "../../../src/assets/images/logo/logo1.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

const milestonesData = [
  { count: 69, label: "Number of Projects Completed" },
  { count: 25, label: "Happy Customers" },
  { count: 97, label: "Increased Customer Satisfaction (%)" },
  { count: 3, label: "Years of Experience" },
];

const Footer = () => {
  const [counts, setCounts] = useState(milestonesData.map(() => 0));

  useEffect(() => {
    const interval = setInterval(() => {
      setCounts((prevCounts) =>
        prevCounts.map((val, index) =>
          val < milestonesData[index].count ? val + 1 : val
        )
      );
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="app">
      {/* Milestones Section */}
      <section className="milestones">
        <h2>MILESTONES</h2>
        <div className="milestones-container">
          {milestonesData.map((milestone, index) => (
            <div className="milestone" key={index}>
              <h3>+{counts[index]}</h3>
              <p>{milestone.label}</p>
            </div>
          ))}
        </div>
        <div className="cta">
          <h3>READY TO GET STARTED?</h3>
          <p>Contact us now</p>

          <button
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/popappz-software-solutions-llp/?viewAsMember=true",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Contact Us
          </button>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-logo">
          <div className="logo">
            <img src={footerLogo} alt="Logo" />
          </div>
          <p>
            Global destination for specialized IT solutions, ideal for startups
            and businesses seeking to enhance their digital presence.
          </p>
          <div className="social-icons">
            <a className="social-icon">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="social-icon">
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/pop_appz/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/popappz-software-solutions-llp/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>

        <div className="footer-column contact-info">
          <div>
            <h4>Contact Us</h4>
            <p>enquiry.popappz@gmail.com</p>
            <p>
              Address: No.129, Shop No.130, Plaza Center, GN Chetty Road, T
              Nagar, Chennai-17
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

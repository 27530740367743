import React from "react";
// import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
// import Contact from "./components/Contact";
import { useLandingPage } from "./_hooks/useLandingPage";
import OurGoals from "./components/Ourgoals";
import Footer from "../../components/Footer/Footer";
import Portfolio from "./components/Portfolio";
import DesignAndDevelopmentApproach from "./components/DesignAndDevelopmentApproach";
import Gallery from "./components/Gallery";
import OurTeam from "./components/OurTeam";
import ScrollTop from "../../components/scrollTop";

const LandingPage = () => {
  const { isSticky, isMenuActive, handleMenuClick, activeSection } =
    useLandingPage();

  return (
    <div>
      {/* <Helmet>
        <link
          href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
          rel="stylesheet"
        />
        <title>Althaf Hussain Portfolio</title>
      </Helmet> */}

      <Header
        isSticky={isSticky}
        handleMenuClick={handleMenuClick}
        activeSection={activeSection}
        isMenuActive={isMenuActive}
      />

      {/* Home Component */}
      <section id="home">
        <Home />
      </section>

      {/* About Component */}
      <section id="about">
        <About />
      </section>

      {/* Gallery Component */}
      <section id="gallery">
        <Gallery />
      </section>

      {/* Services Component */}
      <section id="services">
        <Services />
      </section>

      {/* Our Goals Component */}
      <section id="our-goals">
        <OurGoals />
      </section>

      {/* Portfolio Component */}
      <section id="portfolio">
        <Portfolio />
      </section>

      {/* Development Aproach Component */}
      <section id="development-approach">
        <DesignAndDevelopmentApproach />
      </section>

      <section id="our-team">
        <OurTeam />
      </section>

      {/* Footer Component */}
      <section id="our-goals">
        <Footer />
      </section>

      {/* Contact Component */}
      {/* <section id="contact">
        <Contact />
      </section> */}
      <ScrollTop />
    </div>
  );
};
export default LandingPage;

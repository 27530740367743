import React from "react";
import "../styles/Gallery.scss";
import image1 from "../../../assets/images/Gallery/image1.JPG";
import image2 from "../../../assets/images/Gallery/image2.JPG";
import image3 from "../../../assets/images/Gallery/image3.png";
import image4 from "../../../assets/images/Gallery/image4.jpeg";
import image5 from "../../../assets/images/Gallery/image5.jpeg";
import image6 from "../../../assets/images/Gallery/image6.jpeg";
import image7 from "../../../assets/images/Gallery/image7.jpeg";

const images = [image1, image2, image3, image4, image5, image6, image7];

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="heading">
        <h1>Gallery</h1>
      </div>
      <div className="photo-section-wrapper">
        <div className="photo-section">
          <div className="photo-track">
            {[...images, ...images].map((image, index) => (
              <img key={index} src={image} alt={`Gallery item ${index + 1}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

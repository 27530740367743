import rocket from "../../assets/images/rocket.png";
import code from "../../assets/images/code-img.png";
import heart from "../../assets/images/heart-rate.png";
import shield from "../../assets/images/shield.png";
import tick from "../../assets/images/success.png";
import lock from "../../assets/images/padlock.png";

export const services = [
  { name: "Web App Development", route: "/web-development" },
  { name: "Mobile App Development", route: "/app-development" },
  { name: "Logo Designing", route: "/logo-designing" },
  { name: "SMO (Social Media Optimization)", route: "/smo" },
  { name: "SEO (Search Engine Optimization)", route: "/seo" },
  { name: "Digital Transformation Services", route: "/dt-services" },
  { name: "Sales And Digital Commerce", route: "/commerce" },
  { name: "AI Business Process And Consultation", route: "/ai" },
  { name: "Custom Application Development", route: "/application-development" },
  { name: "Cloud Managed Services", route: "/cloud-services" },
  { name: "Digital Marketing", route: "/digital-marketing" },
  { name: "Video Editing", route: "/video-editing" },
  { name: "Content Writing", route: "/content-writing" },
];

export const developmentApproachList = [
  {
    icon: rocket,
    bgColor: "black",
    title: "UX Driven Engineering",
    description:
      "We prioritize user experience in every step of our development process. Our UX-driven approach ensures that designs are not only visually appealing but also intuitive and user-friendly.",
  },
  {
    icon: code,
    bgColor: "blue",
    title: "Developing Shared Understanding",
    description:
      "We believe in fostering clear communication between teams and stakeholders. This shared understanding helps us translate ideas into functional and efficient solutions.",
  },
  {
    icon: heart,
    bgColor: "pink",
    title: "Proven Experience and Expertise",
    description:
      "With a track record of successful projects, our team brings extensive expertise to deliver exceptional results that exceed client expectations.",
  },
  {
    icon: shield,
    bgColor: "green",
    title: "Security & Intellectual Property (IP)",
    description:
      "We prioritize the protection of your intellectual property and ensure robust security measures to safeguard your data and assets.",
  },
  {
    icon: tick,
    bgColor: "yellow",
    title: "Code Reviews",
    description:
      "Our rigorous code review process guarantees clean, maintainable, and efficient code that meets the highest standards of quality.",
  },
  {
    icon: lock,
    bgColor: "violet",
    title: "Quality Assurance & Testing",
    description:
      "Through comprehensive testing and quality assurance, we ensure that every product we deliver is reliable, bug-free, and ready for deployment.",
  },
];

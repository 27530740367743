import React from "react";
import { motion } from "framer-motion";
import "../styles/Header.scss";
import logo from "../../../assets/images/logo/logo1.png";

const Header = ({ isSticky, handleMenuClick, isMenuActive, activeSection }) => {
  return (
    <div>
      <header className={`header ${isSticky ? "sticky" : ""}`}>
        {/* Menu Icon */}
        <i
          id="menu-icon"
          className={`bx ${isMenuActive ? "bx-x" : "bx-menu"}`}
          onClick={handleMenuClick}
        ></i>

        {/* Logo */}
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        {/* Navbar */}
        <nav className={`navbar ${isMenuActive ? "active" : ""}`}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mobile-menu"
          >
            <a
              href="#home"
              className={activeSection === "home" ? "active" : ""}
            >
              Home
            </a>
            <a
              href="#about"
              className={activeSection === "about" ? "active" : ""}
            >
              About Us
            </a>
            <a
              href="#services"
              className={activeSection === "services" ? "active" : ""}
            >
              Services
            </a>
            <a
              href="#our-goals"
              className={activeSection === "our-goals" ? "active" : ""}
            >
              Our Goals
            </a>
            <a
              href="#portfolio"
              className={activeSection === "portfolio" ? "active" : ""}
            >
              Clients
            </a>
            <a
              href="#our-team"
              className={activeSection === "our-team" ? "active" : ""}
            >
              Our Team
            </a>
          </motion.div>
        </nav>

        {/* Contact Us Button */}
        <button
          className="btn"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/popappz-software-solutions-llp/?viewAsMember=true",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          Contact Us
        </button>
      </header>
    </div>
  );
};

export default Header;

import React from "react";
import "../styles/Home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import bgImage from "../../../assets/videos/home-section-bg.mp4";
export default function Home() {
  return (
    <div className="home" id="home">
      <video autoPlay loop muted>
        <source src={bgImage} type="video/mp4" />
      </video>
      <div className="home-content">
        <h3>
          Your <span>Vision</span>,
        </h3>
        <h3>
          Our <span>Innovation</span>
        </h3>
        <p>Innovating Tomorrow's Solutions, Today.</p>
        <a href="#about">
          <button className="btn2">
            Let's Started
            <FontAwesomeIcon className="icon" icon={faArrowRight} />
          </button>
        </a>
      </div>
    </div>
  );
}

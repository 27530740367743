import { useEffect, useState } from "react";
import ScrollReveal from "scrollreveal";

export const useLandingPage = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const top = window.scrollY;

      // Toggle sticky class based on scroll position
      setIsSticky(window.scrollY > 100);

      sections.forEach((sec) => {
        const offset = sec.offsetTop - 150;
        const height = sec.offsetHeight;
        const id = sec.getAttribute("id");

        if (top >= offset && top < offset + height) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navlinks = document.querySelectorAll("header nav a");

    navlinks.forEach((link) => {
      if (link.getAttribute("href").includes(activeSection)) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, [activeSection]);

  const handleMenuClick = () => {
    setIsMenuActive((prev) => !prev);
    console.log("Menu Active:", !isMenuActive);
  };

  useEffect(() => {
    const sr = ScrollReveal({
      distance: "50px",
      duration: 1000,
      delay: 150,
      reset: true,
    });

    sr.reveal(".home-content, .heading", { origin: "top" });
    sr.reveal(".home-img, .services-container, .portfolio-box, .contact form", {
      origin: "bottom",
    });
    sr.reveal(".home-content h1, .about-img", { origin: "left" });
    sr.reveal(".home-content p, .about-content h2, .about-content h3", {
      origin: "right",
    });
  }, []);

  return {
    isSticky,
    isMenuActive,
    handleMenuClick,
    activeSection,
  };
};

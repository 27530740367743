import React from "react";
import "../styles/Portfolio.scss";

import Image_1 from "../../../assets/images/partners/1.png";
import Image_2 from "../../../assets/images/partners/2.webp";
import Image_3 from "../../../assets/images/partners/3.png";
import Image_4 from "../../../assets/images/partners/4.JPG";
import Image_5 from "../../../assets/images/partners/5.jpg";
import Image_6 from "../../../assets/images/partners/6.avif";
import Image_7 from "../../../assets/images/partners/7.png";
import Image_8 from "../../../assets/images/partners/8.jpg";
import Image_9 from "../../../assets/images/partners/9.png";
import Image_10 from "../../../assets/images/partners/10.webp";
import Image_11 from "../../../assets/images/partners/11.png";
import Image_12 from "../../../assets/images/partners/12.jpg";
import Image_13 from "../../../assets/images/partners/13.png";

const images = [
  Image_1,
  Image_2,
  Image_3,
  Image_4,
  Image_5,
  Image_6,
  Image_7,
  Image_8,
  Image_9,
  Image_10,
  Image_11,
  Image_12,
  Image_13,
];

const Portfolio = () => {
  return (
    <div className="portfolio">
      <div className="heading-portfolio">
        <h2>WE ARE WORKING WITH</h2>
      </div>
      <div className="partners-section-wrapper">
        <div className="partners-section">
          <div className="partners-track">
            {[...images].map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Partner ${(index % images.length) + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import React from "react";
import { developmentApproachList } from "../LandingPage.constants";
import "../styles/DesignAndDevelopmentApproach.scss";

const DesignAndDevelopmentApproach = () => {
  return (
    <section className="design-development-approach">
      <h2>Our Design and</h2>
      <h3>Development Approach</h3>
      <div className="development-container">
        {developmentApproachList.map((item, index) => (
          <div className="development-box" key={index}>
            <div
              className="icon-container"
              style={{ backgroundColor: item.bgColor }}
            >
              <img src={item.icon} alt={item.title} />
            </div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DesignAndDevelopmentApproach;

import React from "react";
import "../styles/About.scss";
import Image from "../../../assets/images/about-img.png";
import smImage from "../../../assets/images/business-3d-laptop.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import bgVideo from "../../../assets/videos/vecteezy_digital-waves-motion-background.mp4";

export default function About() {
  return (
    <div className="about" id="about">
      <p className="heading">
        Find Out More <span>About Us </span>
      </p>
      <div className="about-content">
        <div className="image-container">
          <img src={Image} className="big-image" alt="About US" />
        </div>
        <div className="about-right">
          <p>Empowering Innovation, Inspiring Success :</p>
          <ul>
            <li>
              We are passionate about transforming ideas into innovative digital
              solutions.
            </li>
            <li>
              Specializing in mobile app development for both Android and Apple
              iPhones, website design, and comprehensive software solutions,
            </li>
            <li>
              We are dedicated to providing cutting-edge technology to meet the
              dynamic needs of our clients.
            </li>
          </ul>
          <p>Unleashing Potential, Igniting Futures:</p>
          <ul>
            <li>
              We believe in the power of young minds and their potential to
              drive change.
            </li>
            <li>
              Our internship programs provide hands-on experience, mentorship,
              and certification, preparing the next generation of tech leaders.
            </li>
            <li>
              We encourage creativity, innovation, and entrepreneurial thinking,
              ensuring that every young talent we nurture has the opportunity to
              turn their ideas into impactful solutions.
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-content">
        <video autoPlay loop muted>
          <source src={bgVideo} type="video/mp4" />
        </video>
        <div className="about-bottom-left">
          <h1 className="first">
            Great<span>Product</span>is
          </h1>
          <h1 className="second">
            built by great<span>teams</span>
          </h1>
        </div>
        <div className="about-bottom-right">
          <button className="btn3">
            See more informations
            <FontAwesomeIcon className="icon" icon={faArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
}

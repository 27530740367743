import { services } from "../LandingPage.constants";
import "../styles/Services.scss";
import { motion } from "framer-motion";

export default function Services() {
  return (
    <div className="services" id="services">
      <motion.h2
        className="heading1"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Our Services
      </motion.h2>

      <motion.h2
        className="heading2"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2, delay: 0.2 }}
      >
        Explore Our <span>Expertise</span>
      </motion.h2>

      <div className="services-container">
        {services.map((service, index) => (
          <motion.div
            className="services-box"
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <h3>{service.name}</h3>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

import React from "react";
import "../styles/OurTeam.scss";

import parthiban from "../../../assets/images/team/parthiban.png";
import suthahar from "../../../assets/images/team/suthahar.png";
import ranga from "../../../assets/images/team/ranga.png";
import althaf from "../../../assets/images/team/althaf.png";

// Merging image and LinkedIn data into one array
const teamMembers = [
  {
    image: parthiban,
    linkedin: "https://www.linkedin.com/in/parthiban-b-321b19b0/",
  },
  { image: suthahar, linkedin: "https://www.linkedin.com/in/suthaharp/" },
  { image: ranga, linkedin: "https://www.linkedin.com/in/j-ranga/" },
  { image: althaf, linkedin: "https://althafs.in" },
];

const OurTeam = () => {
  return (
    <div className="ourTeam">
      <div className="heading">
        <h1>Our Team</h1>
        <h2>Dedicated to helping your Business</h2>
      </div>
      <div className="images-container">
        <div className="image">
          {teamMembers.map((member, index) => (
            <a
              key={index}
              href={member.linkedin} // LinkedIn URL for the member
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={member.image}
                alt={`Partner ${(index % teamMembers.length) + 1}`}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;

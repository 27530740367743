import React from "react";
import "../styles/Ourgoals.scss";
import Image from "../../../assets/images/sustainability.png";
import bgVideo from "../../../assets/videos/our-goals-section-bg.mp4";

const OurGoals = () => {
  return (
    <div className="goals-screen">
      <video autoPlay loop muted className="background-video">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div> {/* Add this overlay */}
      <div className="goals-header">
        <h1>Our Goals</h1>
        <h2>Commitment To SDG's</h2>
      </div>

      <div className="goals-content">
        <p>
          <strong>PopAppz Software Solutions LLP</strong> is dedicated to
          supporting the United Nations Sustainable Development Goals, focusing
          on:
        </p>

        <div className="goals-mission-vision">
          <div className="mission">
            <h3>Our Mission:</h3>
            <p>
              To provide cutting-edge software solutions that support
              organizations in achieving the SDGs, ensuring a positive impact on
              society and the planet.
            </p>
          </div>
          <div className="vision">
            <h3>Our Vision:</h3>
            <p>
              To be a leader in sustainable software development, helping
              businesses integrate ethical practices into their operations while
              promoting environmental stewardship.
            </p>
          </div>
        </div>
      </div>

      <div className="goals-icons">
        <img src={Image} alt="SDG Icons" />
      </div>
    </div>
  );
};

export default OurGoals;
